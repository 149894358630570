import React from "react";
import './App.css';

function App() {
    return (
        <div className="App">
            <iframe className='pdf-iframe' src="https://drive.google.com/file/d/1XOhulSNrjehSHRisam_R-wKofcFRq0sd/preview" />
        </div>
    );
}

export default App;
